<template>
  <div id="ProductList">
    <div class="header">
      <div class="title_group">
        <span>PRODUCTS</span>
        <h2>全部商品</h2>
      </div>
      <div class="filter_box">
        <label class="pc_filter">
          <p>
            商品分類<svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sort-down"
              class="svg-inline--fa fa-sort-down fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
              ></path>
            </svg>
          </p>
          <select v-model="active_category" class="category_selector">
            <option value="all">全部商品</option>
            <option
              v-for="item in product_category"
              :key="`category_${item.category_id}`"
              :value="item.category_id"
            >
              {{ item.name }}
            </option>
          </select>
        </label>
        <label class="pc_filter">
          <p>搜尋</p>
          <input v-model="search_keyword" type="text" class="search_input" />
        </label>
      </div>

      <label class="mb_filter">
        <p>商品分類<i class="fas fa-sort-down"></i></p>
        <select v-model="active_category" class="category_selector">
          <option value="all">全部商品</option>
          <option
            v-for="item in product_category"
            :key="`category_${item.category_id}`"
            :value="item.category_id"
          >
            {{ item.name }}
          </option>
        </select>
      </label>
      <label class="mb_filter">
        <p>搜尋</p>
        <input v-model="search_keyword" type="text" class="search_input" />
      </label>
    </div>

    <div class="list">
      <template v-for="item in filter_products">
        <router-link
          :to="`/product/${item.product_id}`"
          class="card"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
          :key="item.id"
          v-if="item.status != 'N'"
        >
          <parallax-container>
            <parallax-element
              type="depth"
              :parallaxStrength="10"
              tag="div"
              class="img_box"
            >
              <parallax-element
                type="depth"
                :parallaxStrength="20"
                tag="img"
                :src="item.cover"
              />
              <div class="bg_logo">
                <parallax-element
                  type="translation"
                  :parallaxStrength="5"
                  tag="img"
                  src="/img/logo_b.png"
                />
              </div>
            </parallax-element>
            <parallax-element
              type="depth"
              class="title"
              :parallaxStrength="10"
              tag="p"
            >
              {{ item.name }}
            </parallax-element>
            <!-- <parallax-element type="depth" :parallaxStrength="10" tag="p">
            {{ item.category }}
          </parallax-element> -->
            <parallax-element
              type="depth"
              :parallaxStrength="10"
              tag="p"
              class="logo"
            >
              {{ GetCategoryTitle(item.category) }}
            </parallax-element>
          </parallax-container>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import ParallaxContainer from '@/components/MousePrallax/parallax-container';
import ParallaxElement from '@/components/MousePrallax/parallax-element';
export default {
  name: 'ProductList',
  components: {
    ParallaxContainer,
    ParallaxElement,
  },
  data() {
    return {
      products: [],
      product_category: [],
      search_keyword: '',
      active_category: 'all',
    };
  },
  methods: {
    ChangeSearchKeyWord(val) {
      this.search_keyword = val;
    },
    async GetProductData() {
      let result = await this.SendGetData(
        process.env.VUE_APP_BASE_API + 'products/get_product_list.php'
      );
      if (result != 'error') {
        let data = JSON.parse(result.data);
        this.product_category = data.category;
        this.product_category.sort((a, b) => {
          return a.position - b.position;
        });
        this.products = data.products;
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    GetPirce(product) {
      let tmp_product = Object.assign({}, product);
      tmp_product.option_combine.sort((a, b) => {
        return parseInt(a.price) - parseInt(b.price);
      });
      return tmp_product.option_combine.filter((item) => item.status == 'Y')[0]
        .price;
    },
    GetCategoryTitle(val) {
      return this.product_category.filter(
        (item) => item.category_id == val[0]
      )[0].name;
    },
  },
  computed: {
    filter_products() {
      if (this.products.length <= 0) {
        return [];
      } else {
        let data = JSON.parse(JSON.stringify(this.products));
        if (this.active_category != 'all') {
          data = data.filter(
            (item) => item.category.indexOf(this.active_category) != -1
          );
        }
        if (this.search_keyword != '') {
          data = data.filter(
            (item) => item.name.indexOf(this.search_keyword) != -1
          );
        }
        return data.sort((a, b) => {
          return a.position - b.position;
        });
      }
    },
  },
  watch: {
    active_category(val) {
      this.$router.push('/product/list/' + val);
    },
    $route() {
      this.active_category = this.$route.params.type;
    },
  },
  filters: {
    currency(price) {
      let val = (price / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  async mounted() {
    this.active_category = this.$route.params.type;
    this.GetProductData();
  },

  metaInfo() {
    return {
      title: '產品列表｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '產品列表｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '產品列表｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '產品列表｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
